
:root{
  --uw_blue: rgb(4, 121,168);
  --uw_dark_gray: rgb(51, 51, 51);
  --uw_dark_red: rgb(155, 0, 0);
  --uw_light_gray: rgb(230, 230, 230);
  --uw_light_gray_shadow: rgb(220, 220, 220);
  background-color: var(--uw_light_gray);
  color: var(--uw_dark_gray);
}

.App {
  font-family: system-ui;
  background-color: var(--uw_light_gray);
}


.course-search{
  font-size: 1.2rem;
  display: flex;
}

@media only screen and (max-width: 1320px){
  .course-search{
    flex-wrap:wrap;
  }  
}
.course-select{
  flex: 2;
  display: flex;
}

.courses-menu{
  display: flex;
}

.courses-scroll{
  flex: 3;
  height: 100vh;
  border-right: groove;
  border-left: inset;
}

.course-info{
  flex: 2;
}